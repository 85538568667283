import { dex, telegram, x } from "../assets";



// NAV LINKS
export const navLink = [
      { title: 'Home', to: '#' },
      { title: 'Links', to: '/#socials' },
      { title: 'Contract', to: '/#contract' },
      { title: 'Tokenomics', to: '/#tokenomics' },
      { title: 'Chart', to: '/#chart' },
      { title: 'Buy Now', to: 'https://iguanadex.com', external: true },
];

// socials
export const socials = [
      { img: x, url: 'https://x.com/peeptoken_' },
      { img: telegram, url: '' },
      { img: dex, url: '' },
];

// tokenomics

export const tokenomics = [
      {
            name: '420.69T Supply',
            token: ''
      },
      {
            name: '0 Taxes',
            token: ''
      },
      {
            name: 'LP Burned',
            token: ''
      },
      {
            name: 'Ownership Renounced',
            token: ''
      },

];
